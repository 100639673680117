import {createRouter, createWebHistory} from 'vue-router'
import i18n from "@/i18n";

const setLocale = (lang) => {
    i18n.global.locale.value = lang;
};

const routes = [
    {
        path: '/:lang',
        name: 'Home',
        component: () => import('../views/HomeView'),
    },
    {
        path: '/:lang/the-firm',
        name: 'TheFirm',
        component: () => import('../views/TheFirmView'),
    },
    {
        path: '/:lang/cooperation',
        name: 'Cooperation',
        component: () => import('../views/CooperationView'),
    },
    {
        path: '/:lang/publications',
        name: 'Publications',
        component: () => import('../views/PublicationsView'),
    },
    {
        path: '/:lang/publications/see-legal',
        name: 'PublicationsSeeLegal',
        component: () => import('../views/PublicationsSeeLegalView'),
    },
    {
        path: '/:lang/publications/polenak',
        name: 'PublicationsPolenak',
        component: () => import('../views/PublicationsPolenakView'),
    },
    {
        path: '/:lang/affiliations-associations',
        name: 'AffiliationsAssociations',
        component: () => import('../views/AffillationsAssociationsView'),
    },
    {
        path: '/:lang/career',
        name: 'Career',
        component: () => import('../views/CareerView'),
    },
    {
        path: '/:lang/contact',
        name: 'Contact',
        component: () => import('../views/ContactView'),
    },
    {
        path: '/:lang/practices',
        name: 'Practices',
        component: () => import('../views/PracticesView'),
    },
    {
        path: '/:lang/industries',
        name: 'Industries',
        component: () => import('../views/IndustriesView'),
    },
    {
        path: '/:lang/people',
        name: 'People',
        component: () => import('../views/PeopleView'),
    },

    {
        path: '/:lang/rankings',
        name: 'Rankings',
        component: () => import('../views/RankingsView'),
    },

    {
        path: '/:lang/privacy-policy',
        name: 'Privacy Policy',
        component: () => import('../views/PrivacyPolicyView'),
    },

    {
        path: '/:lang/cookie-policy',
        name: 'Cookie Policy',
        component: () => import('../views/CookiePolicyView'),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
})

router.beforeEach((to, from, next) => {
    const lang = to.params.lang; //Lang from path variable

    if (!['en', 'mk'].includes(lang)) { //supported languages
        if (!['the-firm', 'cooperation', 'publications', 'publications/see-legal', 'publications/polenak', 'affiliations-associations', 'career', 'contact', 'practices', 'industries', 'people', 'rankings', 'privacy-policy', 'cookie-policy'].includes(lang)) {
            if (lang === undefined) {
                if (localStorage.getItem('selectedLanguage')) {
                    return next({path: `/${localStorage.selectedLanguage}`})
                } else {
                    localStorage.setItem('selectedLanguage', 'en');
                }
            }

            localStorage.setItem('selectedLanguage', 'en');
            return next({path: '/en'})
        }

        let redirectTo = `/en${to.path}`;
        if (localStorage.getItem('selectedLanguage')) {
            setLocale(localStorage.getItem('selectedLanguage'));
            redirectTo = `/${localStorage.getItem('selectedLanguage')}${to.path}`;
        } else {
            setLocale("en");
            localStorage.setItem('selectedLanguage', 'en');
        }

        return next({path: redirectTo});
    } else {
        setLocale(lang);
        localStorage.setItem('selectedLanguage', lang);

        return next()
    }
});

export default router