import {createApp, h} from 'vue'
import App from './App.vue'
import './less/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import $ from 'jquery'
import i18n from "@/i18n";
import router from './router'

window.axios = require('axios');
window.jQuery = window.$ = $

const app = createApp({
    render: () => h(App),
})

if (!localStorage.getItem('selectedLanguage')) {
    localStorage.setItem('selectedLanguage', 'en')
}

app.config.globalProperties.$locale = localStorage.selectedLanguage
app.config.globalProperties.trans = i18n.global.t;

app.mixin({
    data() {
        return {
            title: 'Polenak Law Firm'
        }
    },
    methods: {
        setTitle: function (customTitle) {
            if (customTitle) {
                document.title = customTitle + " | " + this.title
            } else {
                document.title = this.title
            }
        }
    },
})

app.use(router, i18n)
app.mount('#app')