import {createI18n} from "vue-i18n";
import mk from './translations/trans_mk.json'
import en from './translations/trans_en.json'

const i18n = createI18n({
    legacy: false,
    locale: "en",
    globalInjection: true,
    messages: {
        en: en,
        mk: mk
    }
});
export default i18n;