<template>
    <footer class="footer">
        <div class="footer-top bg-blue opacity-75 py-4">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 d-flex align-items-center">
                        <img v-if="this.$locale === 'en'" src="../assets/icons/logo_en_green_light.svg" class="logo-footer"/>
                        <img v-if="this.$locale === 'mk'" src="../assets/icons/logo_mk_green_light.svg" class="logo-footer"/>
                    </div>
                    <div class="col-lg-4 d-lg-block d-none"></div>
                    <div class="col-lg-4 text-lg-end mt-lg-0 mt-4 d-lg-flex justify-content-lg-end align-items-lg-end">
                        <p class="footer-contact-info text-green-light m-0">
                            {{ this.trans("PAGES.CONTACT.POLENAK") }}
                            <br/>{{ this.trans("PAGES.CONTACT.ADDRESS_1") }}
                            <br/>{{ this.trans("PAGES.CONTACT.ADDRESS_2") }}
                            <br/>{{ this.trans("PAGES.CONTACT.ADDRESS_3") }}
                            <br/>{{ this.trans("PAGES.CONTACT.TEL") }}: <a href="tel:+38923114737" target="_blank" class="fw-semibold">+389 2 3114 737</a>
                            <br/>{{ this.trans("PAGES.CONTACT.FAX") }}: <a href="tel:+38923120420" target="_blank" class="fw-semibold">+389 2 3120 420</a>
                            <br/><a href="mailto:info@polenak.com" target="_blank"
                                    class="fw-semibold">info@polenak.com</a>
                        </p>
                        <a href="https://www.linkedin.com/company/polenak-law-firm/" target="_blank" class="social-icon">
                            <img src="../assets/icons/linkedin.png" alt="LinkedIn">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 d-lg-block d-none"></div>
                    <div class="col-lg-4 text-lg-center order-lg-0 order-1 mt-lg-0 mt-2">
                        <p class="footer-bottom-text text-green-light mb-0">
                            &copy; {{ new Date().getFullYear() }} by Polenak Law Firm
                        </p>
                    </div>
                    <div
                        class="col-lg-4 text-lg-end order-lg-1 order-0 d-lg-flex align-items-lg-center justify-content-lg-end">
                        <router-link to="/cookie-policy">
                            <p class="footer-bottom-text text-green-light mb-lg-0 mb-1 me-lg-4">
                                {{ this.trans("TITLES.COOKIE_POLICY") }}
                            </p>
                        </router-link>
                        <router-link to="/privacy-policy">
                            <p class="footer-bottom-text text-green-light mb-lg-0 mb-2">
                                {{ this.trans("TITLES.PRIVACY_POLICY") }}
                            </p>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    watch: {
        '$route'() {
            this.$locale = localStorage.selectedLanguage
            this.$forceUpdate()
        }
    },
}
</script>

<style scoped>

</style>