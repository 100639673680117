<template>
    <header class="main-header">
        <nav class="nav-top">
            <div class="container">
                <div class="row">
                    <div class="centered-menu d-flex w-100">
                        <ul class="nav-list list-unstyled d-inline-flex justify-content-center w-100 p-0 m-0">
                            <li class="list-item">
                                <router-link class="nav-link" :to="`/${this.$locale}`">
                                    <p class="nav-link-text">{{ this.trans("NAV_ITEMS.HOME") }}</p>
                                </router-link>
                            </li>
                            <li class="list-item nav-item dropdown">
                                <a class="nav-link dropdown-toggle nav-link-text" href="#" data-bs-toggle="dropdown">
                                    <p class="nav-link-text">{{ this.trans("NAV_ITEMS.EXPERTISE") }}</p>
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <router-link class="dropdown-item" :to="`/${this.$locale}/practices`">{{ this.trans("NAV_ITEMS.PRACTICES") }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" :to="`/${this.$locale}/industries`">{{ this.trans("NAV_ITEMS.INDUSTRIES") }}</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="list-item">
                                <router-link class="nav-link" to="/people">
                                    <p class="nav-link-text">{{ this.trans("NAV_ITEMS.PEOPLE") }}</p>
                                </router-link>
                            </li>
                            <li class="list-item nav-item dropdown">
                                <a class="nav-link dropdown-toggle nav-link-text" href="#" data-bs-toggle="dropdown">
                                    <p class="nav-link-text">{{ this.trans("NAV_ITEMS.ABOUT_US") }}</p>
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <router-link class="dropdown-item" :to="`/${this.$locale}/the-firm`">{{ this.trans("NAV_ITEMS.THE_FIRM") }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" :to="`/${this.$locale}/cooperation`">{{ this.trans("NAV_ITEMS.COOPERATION") }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" :to="`/${this.$locale}/rankings`">{{ this.trans("NAV_ITEMS.RANKINGS") }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" :to="`/${this.$locale}/affiliations-associations`">{{ this.trans("NAV_ITEMS.AFFILIATIONS_ASSOCIATIONS") }}</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="list-item nav-item dropdown">
                                <a class="nav-link dropdown-toggle nav-link-text" href="#" data-bs-toggle="dropdown">
                                    <p class="nav-link-text">{{ this.trans("NAV_ITEMS.PUBLICATIONS") }}</p>
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <router-link class="dropdown-item" :to="`/${this.$locale}/publications`">{{ this.trans("NAV_ITEMS.VIEW_ALL") }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" :to="`/${this.$locale}/publications/polenak`">{{ this.trans("NAV_ITEMS.POLENAK_LAW_FIRM") }}</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" :to="`/${this.$locale}/publications/see-legal`">{{ this.trans("NAV_ITEMS.SEE_LEGAL") }}</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="list-item">
                                <router-link class="nav-link" :to="`/${this.$locale}/career`">
                                    <p class="nav-link-text">{{ this.trans("NAV_ITEMS.CAREER") }}</p>
                                </router-link>
                            </li>
                            <li class="list-item">
                                <router-link class="nav-link" :to="`/${this.$locale}/contact`">
                                    <p class="nav-link-text">{{ this.trans("NAV_ITEMS.CONTACT") }}</p>
                                </router-link>
                            </li>
                        </ul>
                        <div class="flags-container d-flex align-items-center">
                            <img src="../assets/icons/flag_en.svg" alt="Flag EN" class="flag pointer" @click="changeLocale('en')">
                            <img src="../assets/icons/flag_mk.svg" alt="Flag MK" class="flag pointer" @click="changeLocale('mk')">
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="nav-bottom">
            <div class="container">
                <div class="row">
                    <div class="text-center nav-logo-placeholder">
                        <router-link :to="`/${this.$locale}`" class="m-auto">
                            <img v-if="this.$locale==='en'" src="../assets/icons/logo_en.svg" class="logo-nav" alt="Polenak Logo"/>
                            <img v-if="this.$locale==='mk'" src="../assets/icons/logo_mk.svg" class="logo-nav" alt="Polenak Logo"/>
                        </router-link>
                        <button class="push-menu" @click="togglePushMenu">
                            <div class="inside">
                                <div class="top-line"></div>
                                <div class="middle-line"></div>
                                <div class="bottom-line"></div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import i18n from "@/i18n";

const setLocale = (lang) => {
    i18n.global.locale.value = lang;
};

export default {
    name: "NavigationComponent",
    data: function () {
        return {
            popupIsShown: false,
        }
    },
    watch: {
        '$route'() {
            this.$locale = localStorage.selectedLanguage
            this.$forceUpdate()
        }
    },
    methods: {
        changeLocale(lang) {
            this.$locale = lang;
            setLocale(lang);

            let path = this.$router.currentRoute.value.fullPath.split('/');

            if (!['en', 'mk'].includes(path[path.length - 1]) || !['en', 'mk'].includes(path[path.length - 2])) {
                if (path.length === 2) {
                    this.$router.push({path: `/${lang}`})
                } else {
                    if (path.length < 4) {
                        this.$router.push({path: `/${lang}/${path[path.length - 1]}`})
                    } else {
                        this.$router.push({path: `/${lang}/${path[path.length - 2]}/${path[path.length - 1]}`})
                    }
                }
            } else {
                this.$router.push({path: `/${lang}`})
            }
        },

        togglePushMenu() {
            localStorage.setItem("lastScrollYPosition", window.scrollY.toString());
            document.getElementById('push-menu').classList.add("visible")
            document.getElementsByTagName('body')[0].classList.add("no-scroll");
            document.getElementsByTagName('html')[0].classList.add("no-scroll");
        }
    }
}
</script>

<style scoped>

</style>