<template>
    <div id="push-menu" class="push-menu-item">
        <img class="close-icon" src="@/assets/icons/close.svg" alt="" @click="togglePushMenu">
        <div class="content">
            <router-link class="dropdown-item" :to="`/${this.$locale}/`">{{ this.trans("NAV_ITEMS.HOME") }}</router-link>
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            {{ this.trans("NAV_ITEMS.EXPERTISE") }}
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                         data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <router-link class="dropdown-item" :to="`/${this.$locale}/practices`">{{ this.trans("NAV_ITEMS.PRACTICES") }}</router-link>
                            <router-link class="dropdown-item" :to="`/${this.$locale}/industries`">{{ this.trans("NAV_ITEMS.INDUSTRIES") }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <router-link class="dropdown-item" :to="`/${this.$locale}/people`">{{ this.trans("NAV_ITEMS.PEOPLE") }}</router-link>
            <div class="accordion" id="accordionExample2">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne2">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne2" aria-expanded="false" aria-controls="collapseOne2">
                            {{ this.trans("NAV_ITEMS.ABOUT_US") }}
                        </button>
                    </h2>
                    <div id="collapseOne2" class="accordion-collapse collapse" aria-labelledby="headingOne2"
                         data-bs-parent="#accordionExample2">
                        <div class="accordion-body">
                            <router-link class="dropdown-item" :to="`/${this.$locale}/the-firm`">{{ this.trans("NAV_ITEMS.THE_FIRM") }}</router-link>
                            <router-link class="dropdown-item" :to="`/${this.$locale}/cooperation`">{{ this.trans("NAV_ITEMS.COOPERATION") }}</router-link>
                            <router-link class="dropdown-item" :to="`/${this.$locale}/rankings`">{{ this.trans("NAV_ITEMS.RANKINGS") }}</router-link>
                            <router-link class="dropdown-item" :to="`/${this.$locale}/affiliations-associations`">{{ this.trans("NAV_ITEMS.AFFILIATIONS_ASSOCIATIONS") }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion" id="accordionExample3">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne3">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne3" aria-expanded="false" aria-controls="collapseOne3">
                            {{ this.trans("NAV_ITEMS.PUBLICATIONS") }}
                        </button>
                    </h2>
                    <div id="collapseOne3" class="accordion-collapse collapse" aria-labelledby="headingOne3"
                         data-bs-parent="#accordionExample3">
                        <div class="accordion-body">
                            <router-link class="dropdown-item" :to="`/${this.$locale}/publications`">{{ this.trans("NAV_ITEMS.VIEW_ALL") }}</router-link>
                            <router-link class="dropdown-item" :to="`/${this.$locale}/publications/polenak`">{{ this.trans("NAV_ITEMS.POLENAK_LAW_FIRM") }}</router-link>
                            <router-link class="dropdown-item" :to="`/${this.$locale}/publications/see-legal`">{{ this.trans("NAV_ITEMS.SEE_LEGAL") }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <router-link class="dropdown-item" :to="`/${this.$locale}/career`">{{ this.trans("NAV_ITEMS.CAREER") }}</router-link>
            <router-link class="dropdown-item" :to="`/${this.$locale}/contact`">{{ this.trans("NAV_ITEMS.CONTACT") }}</router-link>
        </div>
    </div>
    <div class="wrapper">
        <div class="wrapper-inside">
            <Navigation/>
            <router-view/>
        </div>
        <div class="scroll-to-top pointer" @click="scrollToTop">
            <img src="@/assets/icons/chevron.svg" alt="Chevron">
        </div>
        <Footer/>
    </div>
</template>

<script>
import Navigation from "@/components/NavigationComponent";
import Footer from "@/components/FooterComponent";

export default {
    name: 'App',
    data: function () {
        return {
            popupIsShown: false,
        }
    },
    mounted() {
        this.setTitle()
    },
    components: {
        Footer,
        Navigation
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    watch: {
        '$route'() {
            this.togglePushMenu();
            this.$locale = localStorage.selectedLanguage
            this.$forceUpdate()
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        togglePushMenu() {
            document.getElementById('push-menu').classList.remove("visible")
            document.getElementsByTagName('body')[0].classList.remove("no-scroll");
            document.getElementsByTagName('html')[0].classList.remove("no-scroll");
            let lastScrollYPosition = parseInt(localStorage.getItem("lastScrollYPosition"));
            if (!isNaN(lastScrollYPosition)) {
                window.scrollTo(0, lastScrollYPosition);
            }
        },
        myEventHandler() {
            if (window.innerWidth > 768) {
                document.getElementById('push-menu').classList.remove("visible")
            }
        }
    }
}
</script>
